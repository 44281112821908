export default [
  'Los rifles de francotirador son efectivos en un largo alcance.',
  'Las escopetas, los SMG y las pistolas son efectivas a corta distancia.',
  'A medida que el ojo de la tormenta se encoge, el daño de la tormenta aumenta.',
  '¿Hay jugadores escondidos en un edificio? Derribélo.',
  'Puede cambiar el orden de los elementos en la pantalla de inventario.',
  'Agachesé para reducir su perfil, u ocúltese detrás de objetos más pequeños.',
  'No tienes cobertura? Construye tu propia.',
  'Epic nunca te pedirá tu contraseña.',
  'Intenta caer siempre en zonas menos pobladas, para evitar combates al principio y poder hacerte con más recursos.',
  'Salta en picado para ir más rápido y llegar a la zona que deseas antes que el resto.',
  'Si quieres durar más en las partidas, opta por una estrategia más pasiva y evita las confrontaciones.',
  'Lo que importa es como se acaba, no como se empieza. Si empiezas mal y no consigues buenos recursos, eso no tiene porqué ser decisivo al final de la partida.',
  'Evita la zona del nuevo cráter porque está infectada de usuarios. Tampoco visites zonas como Pisos Picados, porque lo más seguro es que acabes mal en los primeros minutos.',
  'Aunque estés en una zona tranquila no bajes la guardia: siempre puede haber algún usuario esperándote para eliminarte.',
  'No tengas prisa por cumplir todos los objetivos semanales el primer día, tienes toda una temporada completa para ello y ganarás la misma experiencia.',
  'Si encuentras una buena zona, construye una base y pon trampas.',
  'Construye la base con metal y ladrillo, pero mientras se hace protege con madera.',
  'Nunca te quedes quieto, aunque no hagas nada debes moverte porque nunca se sabe desde dónde te pueden estar apuntando.',
  'Haz uso de los nuevos objetos desde el primer día, así sorprenderás a otros usuarios que aún no los conocen.',
  'Con cada actualización de Epic Games revisa los cambios para sacar partido de ellos. Muchos jugadores, por pereza, no se los leen.',
  'No tengas miedo en reasignar los botones de PC y consola si te vienen mejor para rendir más en las partidas.',
  'Aprovecha la zona de entrenamiento antes de entrar a partida y observa a tus rivales.',
  'El pico es muy importante al principio para conseguir materiales y recursos, además de para saquear.',
  'Prioriza los consumibles de salud frente a los de protección.',
  'Al principio, utiliza cada arma para aprender sus puntos fuertes y débiles.',
  'Durante las primeras horas de juego no temas a morir, así sabrás cómo te matan y podrás evitarlo más adelante.',
  'Si alguien huye de ti es porque no tienen munición, así que ve tras ellos.',
  'Toma un papel y boli y apunta los cofres de cada una de las áreas del juego. Debes aprendértelos de memoria.',
  'Si durante un combate ves que tienes las de perder, huye. Aquí no es de cobardes, es de listos.',
  'Usa el modo repetición para saber cómo te han matado y no cometer los mismos errores.',
  'No dudes en pasarte por Twitch para ver cómo juegan los profesionales e imitar sus estrategias.',
  'Piensa lo que vas a estar haciendo en el siguiente minuto para adelantarte a los acontecimientos.',
  'Aprende el sonido de cada arma, para entender lo que te puedes encontrar.',
  'Cuando recojas la estrella del tesoro del desafío semanal, destruye la zona circundante para confundir a los usuarios que vengan después.',
  'Siempre es bueno tener una buena conexión a Internet y contar con el mejor equipo (si juegas en PC).',
  'Abre cofres aunque no necesites nada, así quitarás recursos a otros rivales.',
  'Observa el escenario para saber si por ahí ya ha pasado otra persona.',
  'Practica todos los días: recuerda que también cuentas con desafíos diarios.',
  'A veces es mejor esconderse.',
  'Aunque no esté bien visto, puedes quedarte escondido en un lugar y atacar con tu rifle cuando nadie se dé cuenta.',
  'No te cabrees si te matan, es totalmente normal.',
  'Nunca te confíes, es el error habitual del novato.',
  'Muévase de un árbol a otro para obtener una toma más cercana, escóndase detrás de las rocas, etc. Usted es un pato sentado al aire libre, y si la cubierta más cercana está a 15 segundos de distancia, esos son 15 segundos que potencialmente pueden derribarla. En esa situación, deberás comenzar a construir cobertura, pero las posibilidades de que se destruya son altas. También puedes abusar de una cámara en tercera persona y permanecer oculto detrás de la cubierta sin perder de vista a tu enemigo.',
  'Juega algunas rondas de práctica. De forma indirecta puedes crear la tuya propio. No son verdaderos juegos de práctica, pero ciertamente te ayudarán a sentirte mejor. Simplemente dirígete a la primera ciudad sobre la que vuela el autobús, trata de encontrar un arma rápidamente y lucha contra cualquiera que veas. Probablemente mueras bastante rápido las primeras veces, pero esto te ayudará a acostumbrarte a los tiroteos de Fortnite. Solía aterrizar en áreas "seguras", pero luego me pasaba 5 minutos jugando al simulador de senderismo y me mataba la primera persona que encontraba porque no era bueno en el combate. Este método te permite entrar y salir rápidamente y te ayuda a obtener una experiencia valiosa.',
  'Aprenda cuándo participar Recuerde, este juego se trata de sobrevivir, no obtener asesinatos. Si ves a alguien lejos y no tienes un francotirador, no comiences a disparar. Probablemente no los va a matar, y está alertando a cualquier persona cercana donde se encuentre. Espera, mira si puedes acercarte sigilosamente y recibir el salto con una escopeta. No sé cuántos juegos perdimos mis amigos y yo desde el principio porque todavía teníamos la mentalidad de "matar a todos" que otros juegos nos inculcaron.',
  'Sé inteligente acerca de tu edificio. Es muy fácil ver a alguien construyendo una estructura a lo lejos, y si veo un fuerte gigante, sé que alguien probablemente esté allí, y lo mismo hacen todos los demás. Guarde sus recursos para la construcción de la cubierta del embrague o las bases del juego final.',
  'Comparte munición con tus compañeros de escuadrón. Los francotiradores y los lanzadores de cohetes son raros, así que recomendaría darle sus balas pesadas o cohetes a la persona de su escuadrón que tenga el arma respectiva. También puede darle a alguien su munición, hacer que llene su revista y devolverla.',
  'Apila elementos con tu escuadrón. No es necesario que cada uno de ustedes lleve un paquete de medicamentos, que una persona cargue los tres y libere espacio en el inventario. Mientras te acerques, deberías poder compartir.',
  'Golpea el círculo azul mientras estás destruyendo cosas. De hecho, es vergonzoso cuánto tiempo tardé en darme cuenta de eso. En lugar de hacer 50 daños, harás 100 y destruirás la estructura más rápido. También te da recursos adicionales',
  'Busca cajas de munición. De hecho, no me di cuenta de que existían hasta que estuve jugando por un tiempo. Son cajas pequeñas y verdes que a menudo están en los estantes.',
  'Bebe tus pociones de escudo de inmediato. No sirve de nada transportarlos, obtener esos escudos inmediatamente. No los acabes tampoco, trata de repartir tus pociones en forma pareja por todo tu escuadrón.',
  'Dispara tus armas automáticas en ráfagas cortas. No necesitamos entablar una pelea por la proliferación de armas aquí, pero creo que todos pueden estar de acuerdo en que la pulverización continuada hace que tu arma sea inútil después de unos pocos disparos. Intente disparar en ráfagas controladas para retener la poca precisión que tiene el arma.',
  'Vuelva a unir sus paredes / escaleras para que pueda acceder fácilmente a ellas. Necesitas a aquellos en MUCHAS situaciones en las que no tienes cobertura y necesitas construir algo RÁPIDO para superar a tu oponente.',
  'Si te atrapa la tormenta, comienza una rampa antes de salir. Es realmente agradable si hay árboles dentro del círculo y dar cobertura justo a la altura de tu rampa. Tendría cuidado con este, sin embargo, si alguien lo ve construyendo, es posible que pueda mantenerlo inmovilizado en las afueras de la tormenta.',
  'Asegúrate de que tus estructuras tengan conexión con el suelo. Si alguna estructura no tiene conexión con la tierra, se colapsará. Es decir, si construyes una escalera hacia el cielo, alguien puede dispararle a la base y mandarte al infierno. Esto también significa que puedes derribar las torres preconstruidas, un buen método para matar / dañar / desorientar si alguien no se da cuenta de que te acercas furtivamente a la base.',
  'Preste atención a las animaciones de muerte del enemigo. Si estás jugando dúo o escuadrones, mira cómo las personas mueren / bajan. Si se deforman instantáneamente cuando mueren, estás bien. Si simplemente caen, ten cuidado. Eso significa que todavía tienen compañeros de equipo vivos.',
  'Coge todas las armas al principio. Desde el principio si ves varias armas del mismo tipo, agárralos. No te vayas solo porque ya tienes uno. Son 30 disparos que dejas atrás, especialmente cuando las municiones son de primera. Tus compañeros de escuadrón también pueden no haber tenido la oportunidad de obtener algo bueno, por lo que puedes compartir como el jugador de buen corazón que eres.',
  'Puedes dividir tu munición. Presione X (PC) para mostrar un control deslizante de 0 rondas a todas sus rondas, y está en el centro por defecto. Mantener presionada la tecla shift y arrastrar y soltar también hace caer la mitad de la munición.',
  'Agacharse aumenta tu puntería. Disminuye su floración (diseminación) haciendo que la toma sea más precisa. La forma de disminuirlo al máximo es agacharse, permanecer quieto y acercar la imagen. Es bueno para AR pero no vale la pena hacerlo cuando está cerca con una escopeta. Además, agacharse no afecta a los rifles de francotirador con alcance.',
  'Usa la brújula para llamar lugares a tus compañeros de escuadrón. "¡Él está por allá!" podría significar cualquier cosa, pero tenemos una práctica brújula incorporada para ayudar, e incluso tiene grados específicos de precisión. Este es un verdadero cambio de juego y noté que mi escuadrón estaba mucho mejor después de que lo descubrimos.',
  'Los SMG y las pistolas tienen sus aplicaciones. Algunos pueden estar en desacuerdo conmigo, pero en general los SMG y las pistolas no son geniales y son fácilmente superados por las otras armas, pero he descubierto que pueden ser útiles para algunas cosas. Las balas de luz son bastante comunes, pero otras municiones no siempre son así que prefiero conservarlas si es posible. Usaré mi pistola / SMG para matar enemigos derribados, destruir la cobertura o suprimir enemigos.',
  'Intente sincronizar su despliegue de planeador correctamente y llegue al suelo primero. Cuando comencé, intentaba hacer paracaidismo horizontal tan lejos como podía para llegar a mi objetivo, pero eso terminó en que llegué más despacio al suelo y me faltaba el botín bueno (y generalmente me mataban por eso). Puede ser lo mejor para usted descender tan verticalmente como pueda y usar el parapente para cubrir la distancia horizontal. Llegarás al suelo más rápido de esta manera y podrás elegir primero el botín. Además, intenta aterrizar encima de las estructuras. Puede romper los techos de la casa y obtener los cofres dorados en los áticos más rápido o simplemente caer al suelo mucho más rápido.',
  'Aprende algunos patrones de construcción. Hay algunos patrones buenos para construir un fuerte rápido, que puede ser útil en el último juego. Intenta buscar en YouTube o ver una buena transmisión. Soy parcial a un 2x2 con escaleras de caracol hacia arriba y un conjunto de escaleras en la parte superior para disparar.',
  'Ordene sus armas lógicamente. Si tienes tiempo. Puede ingresar el menú de su artículo y reordenar sus artículos. Esto le permite colocar un arma de alcance cercano después de su francotirador para disminuir el tiempo de cambiar a un arma adecuada si alguien se acerca.',
  'Cubra la tierra más rápido. Si se aleja mucho del autobús, la manera más rápida de cubrir el terreno es spammeando el botón para ir y venir entre planeador y paracaidismo. Una vez que te encuentres a una cuadra de distancia, bucea directamente hacia abajo y deslizarse hacia arriba es lo mejor.',
  'Acostúmbrate a los edificios de spam para cubrirse. Condición usted mismo para crear spam su estructura rápida preferida, ya sea que sea algo sofisticado o simplemente un muro. Lleva tiempo convertir eso en memoria muscular, pero el spam construye una pared y una rampa cada vez que comienzo una pelea inesperada. Literalmente puede bloquear cualquier fuego de un enemigo frente a ti porque puedes construir muros al pararte sin parar en las escaleras haciendo clic en el spam. Simplemente desperdiciarán su munición, pero se consciente de que esta estrategia puede consumir bastantes recursos. A veces es mejor correr detrás de otra cubierta mientras construyes muros en la dirección en la que te disparan. Se recomienda construir muros alrededor de las escaleras si se queda en el lugar.',
  'Lo más importante. Después de cada partido, piensa en lo que hiciste mal. ¿Por qué muriste? ¿Qué te llevaste y aprendiste? Por ejemplo, lancé detrás de alguien solo para darme cuenta de que tenía botiquines, y morí de pánico al cambiar a mi escopeta. Aprendí a tener siempre mi arma lista antes de golpear la plataforma de lanzamiento para flanquear a alguien. Este consejo es el verdadero motor para mejorar.',
  'Agáchate, quédate quieto y dispara en ráfagas. Esto es muy importante con AR. Agacharse y quedarse quieto reduce SUSTANCIALMENTE la floración de la AR, lo que le permite golpear objetivos lejanos con facilidad. Disparar en ráfagas reduce el retroceso de las AR automáticas. Si alguna vez se ha preguntado por qué sus disparos AR nunca aterrizan, esta es la razón más probable. Debe tener en cuenta que no debe estar agachado e inmóvil mientras corre el riesgo de que le disparen. Esto te convierte en un blanco fácil para disparos en la cabeza. Construye algo de cobertura, agáchate brevemente, haz algunos toques, ¡y sigue moviéndote!',
  'Siempre esté listo para construir. Cuanto más rápido seas en el sorteo con tu construcción, mejor serás para defenderte si te disparan por un costado o por la espalda. Para la consola, siempre asegúrate de que tu construcción esté en la pared plana cuando corras alrededor, para que puedas lanzar una pared rápidamente si te emboscan y minimizar el daño incluso en la lucha.',
  'Highground es todo. Siempre trate de estar por encima de la persona que está disparando. Mirando hacia abajo a alguien aumenta la posibilidad de obtener un tiro en la cabeza, aumenta la cantidad de cobertura que tiene, y disminuye la cobertura que tienen.',
  'Usa el edificio para tu ventaja en un tiroteo. Esto es muy importante en las peleas de corto alcance en las que un disparo en la cabeza puede ser la diferencia entre la vida y la muerte. Construye sobre una persona para tener mejores posibilidades de un tiro en la cabeza. Bloquee sus disparos de escopeta con una pared rápida y tírelos. Esto se aprende mejor viendo jugar a jugadores muy avanzados, es decir, Ninja o TSM_Myth.',
  'Aprenda a llegar a su destino lo más rápido posible desde el autobús de batalla. En lugar de pensar en cuánto tiempo tendrá que dedicar a paracaidismo + vuelo sin motor para alcanzar su objetivo, piense en el tiempo más temprano posible en el juego que puede llegar a su ubicación. No vueles sobre montañas cuando buceas o te desplegarás muy alto. No tengas miedo de planear temprano si te estás quedando corto. Este es muy importante si aterrizas en áreas altamente disputadas.',
  'No corras al centro del círculo demasiado temprano. Esto es especialmente importante cuanto más tiempo progrese el juego. Si estás justo en el centro del círculo, aumenta tus posibilidades de ser golpeado por bandas desde múltiples direcciones a la vez. Deberías mantenerte más o menos cerca del contorno del círculo y esperar a que la gente corra tras la tormenta o involucrar a personas que corren demasiado cerca del centro. Instalar un fuerte en el medio del círculo es como establecer un faro: todos sabrán que estás allí y todos te desafiarán. Mantente discreto y no atraigas demasiada atención.',
  'Elige tus tiros cuidadosamente Los disparos son un faro en este juego. La gente puede sentirse atraída por los sonidos de tus disparos. Asegúrate de estar preparado para defenderte si te disparan por un costado o por la espalda.',
  'Juega agresivamente y no juegues para ganar, jugar para matar. La sugerencia 1 le pide que aprenda de sus errores, por lo tanto, mientras más errores cometa, más rápido aprenderá. ¿Ves a alguien fortalecido? ¡Acumula allí rápidamente y apúndelos desde arriba! Escucha un tiro? ¡Siguelo! Aterrice en lugares nombrados con más frecuencia. Puede ser frustrante, pero cada muerte te enseñará algo muy valioso si meditas en ello. Te sentirás mejorando en el juego muy rápido. Aconsejaría contra Tilted Towers ya que es demasiado estrecho y difícil. Dispara a los lugares donde generalmente caen 3-4 personas.',
  'Aprende lo que vale la pena guardar y lo que no. Desde mi experiencia, los principiantes tienden a no reunir suficientes suministros de curación. Los escudos pequeños son increíblemente importantes para acumular: pueden curarte por 50 en 4 segundos y puedes apilar 10. Puedes cubrirlos y hacerlos explotar a mitad de la batalla. Las fogatas son muy útiles para completar tu salud. Med-kits también son geniales para tener a mano. Almacene suministros de curación desde el principio, ya que se vuelven más difíciles de encontrar a medida que avanza el juego. Las únicas armas que realmente necesitas son AR y escopetas.',
  'Cuando construyes y dañas una pared (preferiblemente ladrillo), su estado opaco se vuelve transparente a medida que baja la salud. Todos sabemos esto. Sin embargo, lo que muchos no saben es que a través de las partes transparentes de una construcción, el rifle térmico puede medir señales de calor.',
  'Los rifles térmicos también funcionan bien para ver a los jugadores que corren en la tormenta hacia la zona segura.',
  'Si está lanzando C4 en una estructura que alberga a un enemigo, a menudo es mejor lanzar dos o más a la vez, en lugar de uno. La primera explosión destruirá la construcción que tengas como objetivo, liberando el aire libre entre tu oponente y la próxima explosión. Esto es más útil si sabes dónde se encuentra tu oponente, y solo hay una o dos versiones entre ti. Seguir con un arma "rompe paredes" como el LMG, la pistola de tambor o una SMG es mi forma favorita de poner esos últimos clavos en el ataúd.',
  'Colocar una trampa de púas en la pared detrás de una rampa (estructura estándar: rampa con una pared protectora) permitirá que se dispare y golpee a los enemigos a través de la rampa. En otras palabras, coloque una pared, seguida de una trampa, luego una rampa. Ocultar la trampa aún más con paredes a los lados de la rampa hace que sea difícil de detectar a simple vista.',
  'Saber exactamente dónde está alguien y qué está haciendo le brinda una ventaja insuperable cuando llega el momento de tomar decisiones cruciales. Use auriculares o al menos auriculares de dos piezas. La audición direccional precisa tiene sus ventajas. Además, busca constantemente en tu entorno las construcciones o las escurridizas serpientes en la hierba que siempre parecen estar lo suficientemente cerca como para morderte el talón antes de que puedas reaccionar.',
  'Muere dignamente, ve a buscar murra y no te escondas como una señorita.',
  'Usa los puentes para esconderte debajo. Si perfeccionas esta técnica podrás usarla para ahorrar hospedajes en la vida real.',
];
