export default {
  firstUpdate: 1530414000000,
  lastUpdate: 1582165822000,
  users: [
    {
      name: 'gzalo.com',
      category: 'Mejores jugadores',
      db: 'data/db_0.sqlite3',
    },
    {
      name: 'NikAwEsOmE81',
      category: 'Mejores jugadores',
      db: 'data/db_1.sqlite3',
    },
    {
      name: 'DeSartre',
      category: 'Mejores jugadores',
      db: 'data/db_2.sqlite3',
    },
    {
      name: 'dadperez',
      category: 'Mejores jugadores',
      db: 'data/db_3.sqlite3',
    },
    {
      name: 'Nachox86',
      category: 'Mejores jugadores',
      db: 'data/db_4.sqlite3',
    },
    {
      name: 'SypherPK',
      category: 'Malísimos',
      db: 'data/db_5.sqlite3',
      ignore: true,
    },
    {
      name: 'ninja',
      category: 'Malísimos',
      db: 'data/db_6.sqlite3',
      ignore: true,
    },
    {
      name: 'Muselk',
      category: 'Malísimos',
      db: 'data/db_7.sqlite3',
      ignore: true,
    },
    {
      name: 'XulElan',
      category: 'Mejores jugadores',
      db: 'data/db_8.sqlite3',
    },
    {
      name: 'L0VEMACHiNEtw',
      category: 'Mejores jugadores',
      db: 'data/db_9.sqlite3',
    },
  ],
};
